import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import blockConductor from '../blocks/block-conductor';
import ContentFooter from '../components/content-footer';
import Header from '../components/header';
import Layout from '../components/layout';
import SEO from '../components/SEO';
import SectionOpener from '../sections/section-opener/section-opener';

class CampaignPage extends Component {
  render() {
    const { data, location } = this.props;
    const { contentfulCampaignPage: page } = data;

    let renderedBlocks = null;
    if (page.body_blocks) {
      renderedBlocks = page.body_blocks.map((block, i) => blockConductor(block, i));
    }

    return (
      <Layout navbarIsInverted={false}>
        <SEO
          title={page.title}
          description={page.intro_text?.intro_text}
          metadata={page.metadata}
        />
        <SectionOpener>
          <Header heading={page.title} />
          {page.intro_text && <p>{page.intro_text.intro_text}</p>}
        </SectionOpener>

        {renderedBlocks && <>{renderedBlocks}</>}

        {page.contact_persons && (
          <ContentFooter contacts={page.contact_persons ? page.contact_persons : null} />
        )}
      </Layout>
    );
  }
}

export default CampaignPage;

export const pageQuery = graphql`
  query ($slug: String!) {
    contentfulCampaignPage(slug: { eq: $slug }) {
      title
      main_image {
        title
        file {
          contentType
        }
        gatsbyImageData(layout: CONSTRAINED, width: 500)
      }
      intro_text {
        intro_text
        childMarkdownRemark {
          html
        }
      }
      contact_persons {
        name
        shortName
        active_employee
        avatar {
          title
          file {
            contentType
          }
          gatsbyImageData(layout: CONSTRAINED, width: 200, aspectRatio: 1)
        }
        position
        slug
        email
        office {
          city
        }
      }
      metadata {
        ...Metadata
      }
      ...CampaignPageBlocksFragment
    }
  }
`;

CampaignPage.propTypes = {
  children: PropTypes.node,
  navbarIsInverted: PropTypes.bool,
};
